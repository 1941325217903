import React from 'react';

import firebase from './firebase';

import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore/lite';

import RichEditorExample from './richEditor.js'

const PIGEONCODEURID = "OVft8t7dqGOqXAf4KWEEUWWZnFF2";
const LAIFROVAID = "nH9ADk2vE9gfxCvLb9oiciQteXh2";

class CompositeurInfo extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = 
        {
            left: '0',
            top: '0',
            animation: '',
            visible: 'hidden',
            editState: "preview",
            compositeur: null,
            compositeurData: null,
            readOnly: true
        }
    }

    setEditState()
    {
        const setState = this.setState.bind(this);

        setState({editState: "edit", readOnly: false});
    }

    setPreviewState()
    {
        const setState = this.setState.bind(this);

        setState({editState: "preview", readOnly: true});
    }

    async setLockState(lockState)
    {
        const db = firebase.db;

        let errorCode = "no-error"

        await updateDoc(doc(db, "compositeur", this.state.compositeurData[0] + '_' + this.state.compositeurData[1]), {
            unlocked: lockState
        }).catch((error) => {
            // Handle Errors here.
            errorCode = error.code;

            // ...
        });

        if (errorCode === "not-found")
        {
            await setDoc(doc(db, "compositeur", this.state.compositeurData[0] + '_' + this.state.compositeurData[1]), {
                unlocked: lockState
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    async saveState() // TODO: cant save if the editor is not opened
    {
        const db = firebase.db;

        let text = this.getEditorText();

        let errorCode = "no-error"

        await updateDoc(doc(db, "compositeur", this.state.compositeurData[0] + '_' + this.state.compositeurData[1]), {
            data: text
        }).catch((error) => {
            // Handle Errors here.
            console.log(error);
            errorCode = error.code;
            const errorMessage = error.message;

            console.log(error.code);
            console.log(error.message);

            
            // ...
        });

        if (errorCode === "not-found")
        {
            await setDoc(doc(db, "compositeur", this.state.compositeurData[0] + '_' + this.state.compositeurData[1]), {
                data: text
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    async getCompositeurDiv(compositeurData) 
    {
        const setState = this.setState.bind(this);

        const db = firebase.db;

        const selectedDoc = doc(db, "compositeur", compositeurData[0] + '_' + compositeurData[1]);

        const docSnapshot = await getDoc(selectedDoc)

        console.log("Doc exists ?", compositeurData, docSnapshot.exists());

        if(docSnapshot.exists())
        {
            const compositeur = docSnapshot.data();
            console.log("Rendered Compositeur: ", compositeur)

            setState({compositeur: compositeur})

            if(this.refreshEditorText != null)
            {
                if(this.state.compositeur != null)
                    if(this.state.compositeur.data != null)
                        this.refreshEditorText(this.state.compositeur.data);
                    else
                        this.refreshEditorText(null);
                else
                    this.refreshEditorText(null);
            }
        }
        else
            if(this.refreshEditorText != null && this.state.compositeur != null)
                this.refreshEditorText(null);
    }

    hide() 
    {
        const setState = this.setState.bind(this);   

        setState({animation: '', left: '-100%', visible:'hidden', compositeurData: null});
    }

    async show(compositeurData) 
    {
        const setState = this.setState.bind(this);  

        await this.getCompositeurDiv(compositeurData);

        console.log("Shown")

        setState({animation: '', left: '0%', visible:'visible', compositeurData: compositeurData});
    }

    async componentDidMount()
    {
        // Mount the show and hide function in props to make it callable by the parent component.
        this.props.setShow(this.show);
        this.props.setHide(this.hide);
    }

    render()
    {
        let compositeurText = null;
        let compositeurUnlocked = false;

        if(this.state.compositeur != null)
        {
            compositeurText = this.state.compositeur.data;

            if(this.state.compositeur.unlocked != null)
            {
                compositeurUnlocked = this.state.compositeur.unlocked;
                console.log("Unlocked: ", compositeurUnlocked);
            }
        }

        console.log("Compositeur Data: ", this.state.compositeurData)

        let compositeurRenderData;

        if(this.state.compositeurData != null)
        {
            compositeurRenderData = 
            <div className="compositeurs_info_img_div"> 
                <img className="compositeurs_info_img" src={this.state.compositeurData[3]} alt="Rien"/>
                <div>{this.state.compositeurData[0]} {this.state.compositeurData[1]}</div>
            </div>
        }

        const connected = this.props.loggedUser != null ? this.props.loggedUser.uid === PIGEONCODEURID || this.props.loggedUser.uid === LAIFROVAID ? true : false : false;

        return (
            <div className={'compositeur_face ' + this.state.visible} style={{animation: this.state.animation}} > 
                <div className="compositeur_info_div">
                    {compositeurRenderData}
                    {(connected || compositeurUnlocked) ?
                        <div className="compositeur_data_div">
                            <div className="compositeur_data_text_div">
                                <RichEditorExample text={compositeurText} readOnly={this.state.readOnly} getText={text => this.getEditorText = text} setText={f => this.refreshEditorText = f}/>
                            </div>
                        </div> : <div> Work in progress </div> }
                    {connected ? <button className="edit_buttons" onClick={() => this.setEditState()}>Edit</button> : <div></div>}
                    {connected ? <button className="edit_buttons" onClick={() => this.setPreviewState()}>Preview</button> : <div></div>}
                    {connected ? <button className="edit_buttons" onClick={() => this.saveState()}>Save</button> : <div></div>}
                    {connected ? <button className="edit_buttons" onClick={() => this.setLockState(true)}>Unlock</button> : <div></div>}
                    {connected ? <button className="edit_buttons" onClick={() => this.setLockState(false)}>Lock</button> : <div></div>}
                </div>
            </div>);
    }
}

//                                    


export default CompositeurInfo;