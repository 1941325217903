import React from 'react';

import './css/title.css';

//<h1 className="page_title"><b>Musi<br/>Bohemia</b></h1>	

class Header extends React.Component 
{
    render() {return (
    <header className="page_header">
            <div className="title_wrapper">
                {this.props.width > 768 ? <img className="page_title" onClick={() => {this.props.clicked("Accueil")}} src="musibohemiaicon.png" alt="Logo" /> : <div></div>} 
                <h1 className="page_title" onClick={() => {this.props.clicked("Accueil")}}><b>Musi<br/>Bohemia</b></h1>	
                <div className="page_selection_wrapper">
                    {this.props.width > 768 ?
                    <ul className="page_selection">

                        <li className="page_selection_text" onClick={() => {this.props.clicked("Accueil")}} >Accueil</li>
                        <li className="page_selection_text" onClick={() => {this.props.clicked("Musicien")}} >Musiciens</li>
                        <li className="page_selection_text" onClick={() => {this.props.clicked("Oeuvres")}} >Œuvres</li>

                    </ul> : <div></div>} 
                </div>
                { this.props.identified == false ? <button className="auth" onClick={() => {this.props.onIdentification()}}>Sign in</button> : <div className="auth">{this.props.loggedUser.displayName}</div> } 
            </div>
            {this.props.width > 768 ? <div className="title_line">Ressources numériques autour des musiciens de Bohême à Paris à la fin du XVIIIe et au début du XIXe siècle</div> : <div></div>} 
	</header>
    );}
}

export default Header;