import React from "react";

import './css/App.css';

import Header from './header'
import CompositeurTable from './compositeurTable'
import CompositeurInfo from './compositeurInfo'

import firebase from './firebase.js';
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const provider = new GoogleAuthProvider();

class App extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        {
            data: null,
            focusPage: "Accueil",
            width: 0, 
            height: 0,
            identified: false,
            loggedUser: null
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
      
    updateWindowDimensions() 
    {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        console.log(this.state.width)
    }

    componentDidMount() 
    {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() 
    {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    getInfoOn(compositeurData)
    {
        console.log("clicked")
        const setState = this.setState.bind(this);   

        setState({focusPage: "Info"}); 

        this.infoShow(compositeurData)
    }

    identification()
    {
        const setState = this.setState.bind(this);   

        signInWithPopup(firebase.auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log(user);
                // ...

                setState({identified: true, loggedUser: user}); 
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
    }

    async changeFocusedPage(currentFocusPage)
    {
        if(currentFocusPage !== this.state.focusPage)
        {
            // <!-- Accueil Hide >
            console.log(this.state.focusPage)

            if(this.state.focusPage === "Accueil")
            {
                await this.accueilHide();
            }

            // <!-- Info Hide >

            if(this.state.focusPage === "Info")
            {
                await this.infoHide();
            }

            // <!-- Oeuvres Hide >

            if(currentFocusPage === "Accueil")
            {
                this.accueilShow();
                this.setState({focusPage: "Accueil"}); 
            }

            if(currentFocusPage === "Musicien")
            {
                this.setState({focusPage: "Musicien"}); 
            }

            if(currentFocusPage === "Info")
            {
                this.setState({focusPage: "Info"}); 
            }

            if(currentFocusPage === "Oeuvres")
            {
                this.setState({focusPage: "Oeuvres"}); 
            }
        }
    }

    headerClick(selection)
    {
        console.log(selection)

        this.changeFocusedPage(selection);
    }

    render() 
    {
        return (
          <div>
            <Header clicked={(selection) => this.headerClick(selection)}
                    onIdentification={() => this.identification()}
                    identified = {this.state.identified}
                    loggedUser = {this.state.loggedUser}
                    width = {this.state.width}
                    height = {this.state.height} />

            {this.state.focusPage === "Musicien" || this.state.focusPage === "Oeuvres" ? <div>Work in progress</div> : <div></div>}

            <CompositeurTable setShow={show => this.accueilShow = show}
                              setHide={hide => this.accueilHide = hide}
                              getInfoOn={(compositeur) => this.getInfoOn(compositeur)} />

            <CompositeurInfo setShow={show => this.infoShow = show}
                             setHide={hide => this.infoHide = hide}
                             loggedUser={this.state.loggedUser} />
                 
          </div>
        );
    }
}

export default App;
