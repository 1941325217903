import React from 'react';

import getCompositeurList from './compositeurList';

const sleep = (milliseconds) => 
{
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

class CompositeurTable extends React.Component 
{
    constructor(props)
    {
        super(props);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this.state = 
        {
            left: '0',
            data: null,
            savedData: null,
            animation: '',
            visible: ''
        }       
    }

    async hide() 
    {
        const setState = this.setState.bind(this);   

        setState({animation: '1.5s ease-in 0s slideout', left: '-100%', visible:'hidden'});

        await sleep(1500);

        setState({savedData: this.state.data, data: null});
    }

    show() 
    {
        const setState = this.setState.bind(this);   

        setState({animation: '', left: '0%', visible:'visible', data: this.state.savedData, savedData: null});
    }

    async getInfoOn(compositeurData)
    {
        await this.hide();

        this.props.getInfoOn(compositeurData);
    }

    renderCompositeur(compositeurData) 
    {
        return (
            <div className="compositeurs_table_div" onClick={() => {this.getInfoOn(compositeurData)}}> 
                <img className="compositeurs_img" src={compositeurData[3]} alt="Rien"/>
                <div>{compositeurData[0]} {compositeurData[1]}</div>
            </div>
        );
    }

    async componentDidMount()
    {
        const setState = this.setState.bind(this);   

        const componentTable = [];
        const compositeurList = await getCompositeurList();

        for(let i = 0; i < compositeurList.length; i++)
        {
            componentTable.push(this.renderCompositeur(compositeurList[i]));
        }

        setState({data: componentTable}); 

        // Mount the show and hide function in props to make it callable by the parent component.
        this.props.setShow(this.show);
        this.props.setHide(this.hide);
    }

    render() 
    {
        return (
        <div>
            <div className={'compositeur_table ' + this.state.visible} style={{animation: this.state.animation, left: this.state.left}}> 
                {this.state.data == null ? <div> </div> : this.state.data}
            </div>
        </div>);
    }
}

export default CompositeurTable;