function importAll(r) {
    return r.keys().map(r);
}

async function getCompositeurList()
{
    const images = importAll(require.context('./Compositeurs/', false, /\.(png|jpe?g|svg)$/));

    var compositeurList = [];
    
    images.forEach(image => {
        let imageData = image.split("_");

        if(imageData.length === 3)
        {
            imageData[0] = imageData[0].replace("/static/media/", "");
            imageData[2] = imageData[2].replace(/\.(png|jpe?g|svg)$/, "");
            imageData.push(image);

            compositeurList.push(imageData);
        }
    });

    return compositeurList;
}

export default getCompositeurList;