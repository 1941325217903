import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore/lite';
import { getAuth } from "firebase/auth"

import * as firebaseui from 'firebaseui'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyA6SE59FWKPuNieLK6MgrRtc0MMyUuPvwU",
  authDomain: "musibohemia.firebaseapp.com",
  projectId: "musibohemia",
  storageBucket: "musibohemia.appspot.com",
  messagingSenderId: "163366254246",
  appId: "1:163366254246:web:825db28a65fc564f686667",
  measurementId: "G-CJJ4LK84RY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

const firebase = { app, analytics, db, auth, firebaseui };

export default firebase;